import styled from "styled-components"
import { transparentize } from "polished"

export const StyledImageQuestion = styled.article`
  margin: 0;

  .image {
    margin: 0;
    grid-area: question-image;
    background-image: url(${({ imageSrc }) => imageSrc}),
      linear-gradient(
        to right,
        ${({ theme }) => transparentize(1, theme.colors.primary.shade)},
        #1ab0d1
      );

    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
  }

  .speak-buttons {
    grid-area: speak-buttons;
    z-index: 12;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: min-content;

    @media (orientation: portrait) {
      flex-direction: row-reverse;
      justify-content: space-evenly;
      align-self: flex-end;
      justify-self: flex-end;
      gap: 20px;
      margin-bottom: -10px;
    }
    @media (orientation: landscape) {
      flex-direction: row-reverse;
      justify-content: space-evenly;
      align-self: flex-end;
      gap: 40px;
      padding-bottom: 20px;
      margin-left: calc(-100px + 15vw);
    }
  }
`
