import React, { useContext, useEffect } from "react"

import Key from "../Key"
import { StyledKeyboard } from "./KeyboardStyles"
import { KeyboardContext } from "./KeyboardProvider"

const keysToExclude = ["ArrowLeft, ArrowRight, Slash"]

const KeyboardDisplay = ({ ...props }) => {
  const {
    keyboardState,
    maxLength,
    setKeyboardState,
    isKeyDisabled,
    checkIsKeyActive,
    activateKey,
    deactivateKey,
  } = useContext(KeyboardContext)
  const { input, currentMode, lastMode, rows } = keyboardState

  useEffect(() => {
    console.log("current keyboard mode: ", currentMode)
  }, [currentMode])

  useEffect(() => {
    console.log("last keyboard mode: ", lastMode)
  }, [lastMode])

  return (
    <StyledKeyboard {...props}>
      {rows.map((zone, i) => (
        <div className={`row${i === 3 ? " space" : ""}`} key={`row-${i}`}>
          {zone.map(({ id, value, action, ...config }) => (
            <Key
              key={id}
              active={checkIsKeyActive(id)}
              value={value}
              colspan={id === "Shift" ? 2 : 1}
              handleActivate={() => {
                console.log(`key ${id} pressed`)
                activateKey(id)
              }}
              handleDeactivate={() => {
                console.log(`key ${id} released`)
                deactivateKey(id)
              }}
              handleKeyEvent={() => {
                console.log(`key ${id} has been clicked.`)
                if (!keysToExclude.includes(id)) {
                  if (id === "Backspace") {
                    if (input) {
                      setKeyboardState((prev) => ({
                        ...prev,
                        input: input.slice(0, -1),
                        currentMode:
                          input.length - 1 < maxLength
                            ? props.currentAttempt === 4
                              ? "assist"
                              : "default"
                            : "exhausted",
                        cursorPosition:
                          prev.cursorPosition !== 0
                            ? prev.cursorPosition - 1
                            : prev.cursorPosition,
                      }))
                    }
                  } else if (id === "ShiftLeft" || id === "Shift") {
                    setKeyboardState((prev) => ({
                      ...prev,
                      currentMode:
                        currentMode === "default"
                          ? "shift"
                          : currentMode === "shift"
                          ? "default"
                          : currentMode,
                    }))
                  } else {
                    setKeyboardState((prev) => ({
                      ...prev,
                      currentMode:
                        input.length + value.length >= maxLength
                          ? "exhausted"
                          : input.length === 0 && currentMode === "shift"
                          ? "default"
                          : currentMode,
                      lastMode:
                        input.length + value.length >= maxLength
                          ? prev.currentMode === "exhausted"
                            ? prev.lastMode
                            : prev.currentMode
                          : prev.lastMode,
                      input:
                        input.length !== maxLength && !isKeyDisabled(value)
                          ? input.concat(value)
                          : input,
                      cursorPosition: prev.cursorPosition + value.length,
                    }))
                  }
                }
              }}
              {...config}
            />
          ))}
        </div>
      ))}
    </StyledKeyboard>
  )
}

export default KeyboardDisplay
