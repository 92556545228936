import React from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

export const ConfirmationAlertDisplay = ({
  isOpen,
  toggleOpen,
  headerText,
  bodyText,
  handleConfirm,
  handleAbort,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleOpen}>
      <ModalHeader toggle={toggleOpen}>{headerText}</ModalHeader>
      <ModalBody>{bodyText}</ModalBody>
      <ModalFooter>
        <Button outline color="success" onClick={handleConfirm}>
          ✔
        </Button>
        <Button outline color="danger" onClick={handleAbort}>
          ❌
        </Button>
      </ModalFooter>
    </Modal>
  )
}
