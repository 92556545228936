import React from "react"
import PropTypes from "prop-types"
import { ConfirmationAlertDisplay } from "./ConfirmationAlertDisplay"

const ConfirmationAlert = ({
  isOpen,
  toggleOpen,
  headerText,
  bodyText,
  handleConfirm,
  handleAbort,
}) => {
  return (
    <ConfirmationAlertDisplay
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      headerText={headerText}
      bodyText={bodyText}
      handleConfirm={handleConfirm}
      handleAbort={handleAbort}
    />
  )
}

ConfirmationAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  handleConfirm: PropTypes.func.isRequired,
  handleAbort: PropTypes.func.isRequired,
}

export default ConfirmationAlert
