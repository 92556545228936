import React, { useEffect, useState } from "react"
import CarnifexDisplay from "./CarnifexDisplay"
import WordDisplay from "./WordDisplay"

const WordContainer = ({
  hideMissing,
  unleashTheCarnifex,
  segments,
  displayChars = false,
  displayFaulty = false,
}) => {
  // if (unleashTheCarnifex) return <CarnifexDisplay segments={segments} />
  const [filteredSegments, setFilteredSegments] = useState()

  useEffect(() => {
    if (segments && !unleashTheCarnifex) {
      setFilteredSegments(segments.filter((seg) => seg.variant !== "missing"))
    }
  }, [segments, unleashTheCarnifex])

  return unleashTheCarnifex ? (
    <CarnifexDisplay segments={segments || []} />
  ) : (
    <WordDisplay
      segments={(hideMissing ? filteredSegments : segments) || []}
      displayChars={displayChars}
      displayFaulty={displayFaulty}
    />
  )
}

export default WordContainer
