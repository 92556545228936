import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { SmallIconButton } from "../../styles/buttons"

const SpeakButton = styled(SmallIconButton)`
  svg {
    display: block;

    @media (orientation: portrait) {
      padding: 3px;
    }
  }
`

const SpeakButtonDisplay = ({
  onClick,
  children,
  disabled = false,
  className,
  audio,
}) => (
  <SpeakButton disabled={disabled} onClick={onClick} className={className} on>
    {children}
  </SpeakButton>
)

SpeakButtonDisplay.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default SpeakButtonDisplay
