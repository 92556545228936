import React from "react"
import styled from "styled-components"

import StarsIcon from "../../lib/icons/Stars"

import { usePoints } from "../../hooks"

const StyledStarsCounter = styled.div`
  display: grid;
  justify-items: center;
  z-index: 10;
  position: relative;
  width: 100%;

  .stars {
    color: ${({ theme }) => theme.colors.cta.main};
    text-align: center;
    margin-top: -0.8em;
    text-shadow: 0 0.3em 0.3em black;
  }

  svg {
    width: 50px;
    height: 40px;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
      width: 103px;
      height: 80px;
    }
  }
`

const StarsCountDisplay = () => {
  const { stars } = usePoints()

  return (
    <StyledStarsCounter>
      <StarsIcon />
      <span className="stars">{stars}</span>
    </StyledStarsCounter>
  )
}

export default StarsCountDisplay
