import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"

import SpeakButtonDisplay from "./SpeakButtonDisplay"
import useAudio from "../../hooks/useAudio"

const SpeakButtonContainer = ({
  audio,
  rate = 1,
  children,
  disabled = false,
  autoPlay = false,
  className,
}) => {
  // const [play] = useSound(audio, {
  //   interrupt: true,
  //   playbackRate: rate,
  //   autoplay: autoPlay,
  // })
  const [play, audioElement] = useAudio(audio, autoPlay)

  const handleClick = (e) => {
    // audioElement.current.play()
    play()
    e.currentTarget.blur()
  }

  return (
    <SpeakButtonDisplay
      disabled={disabled}
      onClick={handleClick}
      className={className}
      audio={audio}
    >
      {children}
    </SpeakButtonDisplay>
  )
}

SpeakButtonContainer.propTypes = {
  audio: PropTypes.string.isRequired,
  speed: PropTypes.number,
  disabled: PropTypes.bool,
  autoPlay: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default SpeakButtonContainer
