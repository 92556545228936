import React from "react"
import PropTypes from "prop-types"

import { Char, CharCorrect, CharDel, CharFaulty, CharIns } from "./Characters"
import WordWrapper from "./WordWrapper"

const Segment = ({ sequence, variant, displayFaulty, ...char }) => {
  if (variant === "missing") {
    if (displayFaulty) return <CharFaulty {...char}>{sequence}</CharFaulty>
    return <CharDel {...char}>{sequence}</CharDel>
  }
  if (variant === "extra") return <CharIns {...char}>{sequence}</CharIns>
  if (variant === "correct")
    return <CharCorrect {...char}>{sequence}</CharCorrect>
  return <Char {...char}>{sequence}</Char>
}

const Sequence = ({ sequence: segment, variant, displayFaulty, ...char }) => {
  return segment.split("").map((sequence, i) => {
    const props = { ...char, key: `char-${i}` }
    if (variant === "missing") return <CharDel {...props}>{sequence}</CharDel>
    if (variant === "extra") {
      if (displayFaulty) return <CharFaulty {...props}>{sequence}</CharFaulty>
      return <CharIns {...props}>{sequence}</CharIns>
    }
    if (variant === "correct")
      return <CharCorrect {...props}>{sequence}</CharCorrect>
    return <CharCorrect {...props}>{sequence}</CharCorrect>
  })
}

const WordDisplay = ({ segments, displayChars = false, ...props }) => (
  <WordWrapper className="request">
    {segments.map(({ index, ...segment }, i) =>
      displayChars ? (
        <Sequence key={`${i}-${index}`} index={index} {...segment} {...props} />
      ) : (
        <Segment key={`${i}-${index}`} index={index} {...segment} {...props} />
      )
    )}
  </WordWrapper>
)

WordDisplay.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      sequence: PropTypes.string,
      variant: PropTypes.oneOf(["correct", "overlap", "extra", "missing"]),
    })
  ).isRequired,
}

export default WordDisplay
