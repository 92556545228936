import React from "react"

import { StyledImageQuestion } from "./ImageQuestionStyles"
import SpeakerIcon from "../../lib/icons/Speaker"
import MessageIcon from "../../lib/icons/Message"
import SpeakButton from "../Speech"

const ImageDisplay = ({ task, autoPlay = false, ...props }) => (
  <StyledImageQuestion imageSrc={task.img} {...props}>
    <figure className="image" />

    <div className="speak-buttons">
      <div className="speak-button speak-sentence-button">
        <SpeakButton
          audio={task.audioSentence}
          rate={1}
          disabled={!task.sentence}
        >
          <MessageIcon />
        </SpeakButton>
      </div>

      <div className="speak-button speak-word-button">
        <SpeakButton
          audio={task.audioWord}
          rate={1}
          disabled={!task.word}
          autoPlay={autoPlay}
        >
          <SpeakerIcon />
        </SpeakButton>
      </div>
    </div>
  </StyledImageQuestion>
)

export default ImageDisplay
