import styled from "styled-components"

export const StyledStarsFeedback = styled.p`
  display: flex;
  align-items: center;

  span {
    margin-right: ${({ theme }) => theme.spacings.fluid.tiny}vw;
  }

  .stars {
    list-style: none;
    padding-left: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
`
