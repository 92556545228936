import React from "react"
import PropTypes from "prop-types"

import { CharCorrect, CharDel } from "./Characters"
import WordWrapper from "./WordWrapper"

const Segment = ({ sequence, variant, ...char }) => {
  switch (variant) {
    case "missing":
      return sequence.split("").map((c, i) => (
        <CharDel key={`missing-char-${i}`} {...char}>
          {c}
        </CharDel>
      ))
    case "correct":
    case "overlap":
      return sequence.split("").map((c, i) => (
        <CharCorrect key={`correct-char-${i}`} {...char}>
          {c}
        </CharCorrect>
      ))
    default:
      return null
  }
}

const CarnifexDisplay = ({ segments }) => (
  <WordWrapper className="request">
    {segments.map(({ index, ...segment }, i) => (
      <Segment key={`${i}-${index}`} index={index} i={i} {...segment} />
    ))}
  </WordWrapper>
)

CarnifexDisplay.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      sequence: PropTypes.string,
      variant: PropTypes.oneOf(["correct", "overlap", "extra", "missing"]),
    }),
  ).isRequired,
}

export default CarnifexDisplay
