import styled from "styled-components"

export const StyledKeyboard = styled.div`
  user-select: none;

  position: relative;
  display: grid;
  grid-auto-rows: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacings.fluid.tiny}vh;
  width: auto;

  .row {
    display: flex;
    grid-auto-columns: repeat(12, 1fr);
    grid-auto-flow: column;
    width: auto;
    margin-left: 0;
    margin-right: 0;

    justify-items: start;
    gap: ${({ theme }) => theme.spacings.fluid.tiny}vmin;
  }
`
