import styled from "styled-components"

export const StyledEmoji = styled.span`
  font-size: 1em;

  &.s {
    font-size: 0.85em;
  }
  &.xs {
    font-size: 0.7em;
  }
  &.xxs {
    font-size: 0.55em;
  }
  &.l {
    font-size: 1.5em;
  }
  &.xl {
    font-size: 2em;
  }
  &.xxl {
    font-size: 2.5em;
  }
`
