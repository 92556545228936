import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import StarsFeedbackDisplay from "./StarsFeedbackDisplay"
import { calculateStarsFromAttempt } from "../../services/helpers"

const StarsFeedback = ({ attempt, hitsCount }) => {
  const [starsGiven, setStarsGiven] = useState(0)

  useEffect(() => {
    setStarsGiven(calculateStarsFromAttempt({ attempt, hitsCount }))
    console.log("hits count:", hitsCount)
  }, [attempt, hitsCount])

  return <StarsFeedbackDisplay starsGiven={starsGiven} attempt={attempt} />
}

StarsFeedback.propTypes = {
  attempt: PropTypes.number,
  hitsCount: PropTypes.number,
}

export default StarsFeedback
