import React from "react"
import IconBackspace from "../../lib/icons/Backspace"
import IconShift from "../../lib/icons/Shift"

import { ASSIST_RESIST, EXHAUSTED_ENABLED } from "../../const_values"

/* default key configuration */
const KEYS_DEFAULT = [
  { id: "KeyQ", value: "q", row: 0, audioName: "" },
  { id: "KeyW", value: "w", row: 0, audioName: "" },
  {
    id: "KeyE",
    value: "e",
    row: 0,
    className: "vowel",
    audioName: "",
  },
  { id: "KeyR", value: "r", row: 0, audioName: "" },
  { id: "KeyT", value: "t", row: 0, audioName: "" },
  { id: "KeyY", value: "y", row: 0, audioName: "" },
  {
    id: "KeyU",
    value: "u",
    row: 0,
    className: "vowel",
    audioName: "",
  },
  {
    id: "KeyI",
    value: "i",
    row: 0,
    className: "vowel",
    audioName: "",
  },
  {
    id: "KeyO",
    value: "o",
    row: 0,
    className: "vowel",
    audioName: "",
  },
  { id: "KeyP", value: "p", row: 0, audioName: "" },
  {
    id: "Backspace",
    icon: <IconBackspace />,
    row: 0,
    className: "key-backspace",
  },
  {
    id: "KeyA",
    value: "a",
    row: 1,
    className: "vowel",
    audioName: "",
  },
  { id: "KeyS", value: "s", row: 1, audioName: "" },
  { id: "KeyD", value: "d", row: 1, audioName: "" },
  { id: "KeyF", value: "f", row: 1, audioName: "" },
  { id: "KeyG", value: "g", row: 1, audioName: "" },
  { id: "KeyH", value: "h", row: 1, audioName: "" },
  { id: "KeyJ", value: "j", row: 1, audioName: "" },
  { id: "KeyK", value: "k", row: 1, audioName: "" },
  { id: "KeyL", value: "l", row: 1, audioName: "" },
  {
    id: "Shift",
    value: "ABC",
    icon: <IconShift />,
    className: "key-shift",
    row: 2,
  },
  {
    id: "KeyZ",
    value: "z",
    row: 2,
    audioName: "",
  },
  { id: "KeyX", value: "x", row: 2, audioName: "" },
  { id: "KeyC", value: "c", row: 2, audioName: "" },
  { id: "KeyV", value: "v", row: 2, audioName: "" },
  { id: "KeyB", value: "b", row: 2, audioName: "" },
  { id: "KeyN", value: "n", row: 2, audioName: "" },
  { id: "KeyM", value: "m", row: 2, audioName: "" },
]

/* shift keyboard config */
const KEYS_SHIFT = [
  { id: "KeyQ", value: "Q", row: 0, audioName: "" },
  { id: "KeyW", value: "W", row: 0, audioName: "" },
  {
    id: "KeyE",
    value: "E",
    row: 0,
    className: "vowel",
    audioName: "",
  },
  { id: "KeyR", value: "R", row: 0, audioName: "" },
  { id: "KeyT", value: "T", row: 0, audioName: "" },
  { id: "KeyY", value: "Y", row: 0, audioName: "" },
  {
    id: "KeyU",
    value: "U",
    row: 0,
    className: "vowel",
    audioName: "",
  },
  {
    id: "KeyI",
    value: "I",
    row: 0,
    className: "vowel",
    audioName: "",
  },
  {
    id: "KeyO",
    value: "O",
    row: 0,
    className: "vowel",
    audioName: "",
  },
  { id: "KeyP", value: "P", row: 0, audioName: "" },
  {
    id: "Backspace",
    icon: <IconBackspace />,
    row: 0,
    className: "key-backspace",
  },

  {
    id: "KeyA",
    value: "A",
    row: 1,
    className: "vowel",
    audioName: "",
  },
  { id: "KeyS", value: "S", row: 1, audioName: "" },
  { id: "KeyD", value: "D", row: 1, audioName: "" },
  { id: "KeyF", value: "F", row: 1, audioName: "" },
  { id: "KeyG", value: "G", row: 1, audioName: "" },
  { id: "KeyH", value: "H", row: 1, audioName: "" },
  { id: "KeyJ", value: "J", row: 1, audioName: "" },
  { id: "KeyK", value: "K", row: 1, audioName: "" },
  { id: "KeyL", value: "L", row: 1, audioName: "" },

  {
    id: "Shift",
    value: "abc",
    icon: <IconShift />,
    className: "key-shift key-shift--uppercase",
    row: 2,
  },
  {
    id: "KeyZ",
    value: "Z",
    row: 2,
    audioName: "",
  },
  { id: "KeyX", value: "X", row: 2, audioName: "" },
  { id: "KeyC", value: "C", row: 2, audioName: "" },
  { id: "KeyV", value: "V", row: 2, audioName: "" },
  { id: "KeyB", value: "B", row: 2, audioName: "" },
  { id: "KeyN", value: "N", row: 2, audioName: "" },
  { id: "KeyM", value: "M", row: 2, audioName: "" },
]

const getAssistMode = (modes, characters = []) => {
  const baseMode = modes.default
  const activated = new Map(characters.map((k) => [k, false]))

  const keys = baseMode
    .map(
      ({ id, value, disabled = false, className, ...rest }) => {
        let assistMatch

        if (!ASSIST_RESIST.includes(id) && !id.includes("Shift")) {
          assistMatch = characters.find((char) => char.includes(value))
        }

        if (assistMatch) {
          disabled = false
          className = `${className} key-assist`
          activated.set(value, true)
        } else if (!ASSIST_RESIST.includes(id)) {
          disabled = true
        }
        return { id, value, disabled, className, ...rest }
      }
      // Hide shift button in assist mode
    )
    .filter(({ value }) => !value?.includes("Shift"))

  Array.from(activated.entries())
    .filter(([_, v]) => !v)
    .forEach(function ([k]) {
      const key = modes.shift.find(({ value }) => value === k)
      if (!key) return

      activated.set(k, true)
      const index = keys.findIndex(({ id }) => id === key.id)
      keys.splice(index, 0, {
        ...key,
        originalId: key.id,
        id: `${key.id}_shift`,
        className: `${key.className} key-assist`,
        disabled: false,
      })
    })

  return keys
}

export const getKeyboardModes = (characters) => {
  const baseModes = {
    default: KEYS_DEFAULT,
    exhausted: KEYS_DEFAULT.map(({ id, value, disabled, ...key }) => ({
      id,
      disabled: !EXHAUSTED_ENABLED.includes(id),
      value,
      ...key,
    })),
    shift: KEYS_SHIFT,
  }

  const assistMode = getAssistMode(baseModes, characters)

  return { ...baseModes, assist: assistMode }
}
