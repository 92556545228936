import React, { useState, useEffect } from 'react';

function getWindowDimensions(isBrowser) {
  const { innerWidth: width, innerHeight: height } = isBrowser ? window : {innerHeight: 0, innerWidth: 0};
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
    const isBrowser = typeof window !== "undefined"
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions(isBrowser));

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions(isBrowser));
    }

    if (isBrowser) window.addEventListener('resize', handleResize);
    return isBrowser ? () => window.removeEventListener('resize', handleResize) : null
  }, []);

  return windowDimensions;
}