import styled, { css } from "styled-components"
import { fluidRange, transparentize } from "polished"

export const StyledKey = styled.span`
  font-family: "Grundschrift Bold", sans-serif;
  transition: transform 200ms ease, box-shadow 200ms ease;
  box-shadow: 0 0.2em 0
    ${({ theme }) => transparentize(0.5, theme.colors.background)};
  border: 0.1em solid transparent;
  border-radius: 1.5em;
  width: min-content;
  background-color: white;
  color: rgb(10, 10, 10);
  font-weight: 500;
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-content: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  div.row > & {
    width: auto;
    margin-right: 0;
    padding-left: 1.5vmin;
    padding-right: 1.5vmin;

    & .char {
      min-width: 1em;
      min-height: 1.1em;
    }

    @media (orientation: portrait) {
      @media (max-width: 600px) {
        font-size: calc(8px + 0.65vmax);
        padding-top: 2vmin;
        padding-bottom: 2vmin;
      }
      @media (min-width: 601px) {
        font-size: calc(12px + 1vmax);
        padding-top: 1vmin;
        padding-bottom: 1vmin;
      }
    }
    @media (orientation: landscape) {
      @media (max-width: 800px) {
        font-size: calc(10px + 1vmax);
        padding-top: 2vmin;
        padding-bottom: 2vmin;
      }
      @media (min-width: 801px) {
        font-size: calc(14px + 1vmax);
        padding-top: 1vmin;
        padding-bottom: 1vmin;
      }
    }

    &.key-backspace {
      padding-left: 3vmin;
      padding-right: 3vmin;
    }
  }

  &:not(.row) > &:not(:last-child) {
    margin-right: 0;
  }

  .char {
    display: flex;
    transition: transform 20ms ease;
    background-color: inherit;
    border-radius: 1em;
    line-height: 1;

    .key-img {
      @media (orientation: landscape) {
        width: calc(12px + 1.9vw);
        height: calc(9px + 1.9vw);
      }
      @media (orientation: portrait) {
        width: calc(27px + 1vw);
        height: calc(21px + 1vw);
      }
    }
  }

  :not(.key-shift) .char {
    flex-direction: column;
    align-items: center;
  }

  svg {
    width: 1em;
    height: 100%;
    display: block;
  }

  &.vowel {
    background-color: ${({ theme }) => theme.colors.accent.shade};
  }

  &.key-shift {
    padding: 6px 4px;
    .char {
      justify-content: center;
      align-items: center;
      font-size: calc(12px + 1.2vw);
    }

    svg {
      margin-right: 0.2em;
    }

    &--uppercase svg {
      transform: rotate(180deg);
    }
  }

  &.key-assist {
    border-color: ${({ theme }) => theme.colors.accent.main};
  }

  ${({ active }) =>
    active &&
    css`
      transition-duration: 25ms;
      transform: translateY(0.1em);
      box-shadow: 0 0.1em 0 hsla(0, 0%, 0%, 0.35);

      @media (hover: none) {
        .char {
          transform: translateY(-2em) scale(1.5);
          box-shadow: 0 0.5em 0.4em rgba(0, 0, 0, 0.3);
        }
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `}
`
