import React, { useContext, useCallback } from "react"

import { KeyboardContext } from "../Keyboard/KeyboardProvider"

import AnswerInputDisplay from "./AnswerInputDisplay"

const AnswerInput = ({ handleSubmit, currentAttempt, ...props }) => {
  const { keyboardState, setKeyboardState } = useContext(KeyboardContext)
  const { input } = keyboardState

  const setInput = useCallback(
    (e) => {
      e.preventDefault()

      console.log("target value:", e.target.value)
      console.log("target input:", input)

      setKeyboardState((prev) => ({ ...prev, input: e.target.value }))
    },
    [input]
  )

  return (
    <AnswerInputDisplay
      handleSubmit={handleSubmit}
      currentAttempt={currentAttempt}
      setInput={setInput}
    />
  )
}

export default AnswerInput
