import React, { useEffect, useState, useRef, useCallback } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { toast, ToastContainer } from "react-toastify"
import { isMobile } from "react-device-detect"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TaskListing from "../components/TaskListing"
import { getKeyboardModes } from "../components/Keyboard/configurations"
import { PKW_LVLS_BY_SKILL_LVL, UNICODE_HEX_VALUES } from "../const_values"
import KeyboardProvider from "../components/Keyboard/KeyboardProvider"
import { QuizLayout, Loader } from "../styles/common"
import { useStudentStore } from "../../store.js"
import graphqlClient from "../services/graphql-client"
import {
  WORDS_BY_PKW_QUERY,
  CREATE_INPUT_SESSION_MUTATION,
  DELETE_INPUT_SESSION_BY_ID_MUTATION,
} from "../graphql_requests"
import {
  fetchFeedforwardsFromCSV,
  updateCurrentStudentData,
} from "../services/helpers"
import useWindowDimensions from "../hooks/useWindowDimensions"

const TASK_DEFAULT = { maxAttempts: 4, request: [] }
const assetBaseUrl = process.env.GATSBY_ASSET_BASE_URL || ""
const imagePath = process.env.GATSBY_IMAGE_PATH || ""
const audioWordPath = process.env.GATSBY_AUDIO_WORD_PATH || ""
const audioSentencePath = process.env.GATSBY_AUDIO_SENTENCE_PATH || ""

const ErrorMessage = styled.div`
  background-color: white;
  padding: 1em;
  position: absolute;
  inset: 2em;
  overflow: auto;
`

const LoadedExercise = ({
  exercise,
  setWord,
  inputSessionId,
  setInputSessionId,
  wordBuffer,
  setWordBuffer,
  wordBufferIndex,
  getNextWordFromList,
  getFeedForwardFromList,
}) => {
  const student = useStudentStore((store) => store.student)
  const [task, setTask] = useState({
    ...TASK_DEFAULT,
    ...exercise,
    request: exercise.graphemes,
    img: `${assetBaseUrl}/${imagePath}/${exercise.word}.png`,
    audioWord: `${assetBaseUrl}/${audioWordPath}/${exercise.word}_word.mp3`,
    audioSentence: `${assetBaseUrl}/${audioSentencePath}/${exercise.word}_sentence.mp3`,
    playbackRate: 1,
    maxInputLength: exercise.word.length + 5,
  })
  const [modes, setModes] = useState()
  const [chars, setChars] = useState()
  const [isPreparing, setIsPreparing] = useState(true)

  useEffect(() => {
    if (student) {
      setTask((prevTask) => ({
        ...prevTask,
        ...exercise,
        request: exercise.graphemes,
        img: `${assetBaseUrl}/${imagePath}/${exercise.word}.png`,
        audioWord: `${assetBaseUrl}/${audioWordPath}/${exercise.word}_word.mp3`,
        audioSentence: `${assetBaseUrl}/${audioSentencePath}/${exercise.word}_sentence.mp3`,
        playbackRate: 1,
        maxInputLength: exercise.word.length + 5,
        uid: student.id,
      }))
      setChars([...exercise.graphemes])
    }
  }, [exercise, student])

  useEffect(() => {
    if (!!student && !!chars) {
      const newKeyboardModes = getKeyboardModes(chars)
      setModes({ ...newKeyboardModes })
      setIsPreparing(false)
    }
  }, [chars, student])

  return (
    <>
      {!isPreparing && (
        <KeyboardProvider modes={modes} maxLength={task.maxInputLength}>
          <TaskListing
            task={task}
            setWord={setWord}
            inputSessionId={inputSessionId}
            setInputSessionId={setInputSessionId}
            wordBuffer={wordBuffer}
            setWordBuffer={setWordBuffer}
            wordBufferIndex={wordBufferIndex}
            getNextWordFromList={getNextWordFromList}
            getFeedForwardFromList={getFeedForwardFromList}
          />
        </KeyboardProvider>
      )}
    </>
  )
}

const ExerciseDisplay = ({
  queryWord,
  setWord,
  inputSessionId,
  setInputSessionId,
  wordBuffer,
  setWordBuffer,
  wordBufferIndex,
  getNextWordFromList,
  getFeedForwardFromList,
}) => {
  return !!!queryWord ? (
    <ErrorMessage>
      <h2>Ooops</h2>
      <p>
        Unfortunately, the word {queryWord.word} was not found in the database.
        Server response:
      </p>
      <pre>{JSON.stringify(queryWord, null, 2)}</pre>
    </ErrorMessage>
  ) : (
    <LoadedExercise
      exercise={queryWord}
      setWord={setWord}
      inputSessionId={inputSessionId}
      setInputSessionId={setInputSessionId}
      wordBuffer={wordBuffer}
      setWordBuffer={setWordBuffer}
      wordBufferIndex={wordBufferIndex}
      getNextWordFromList={getNextWordFromList}
      getFeedForwardFromList={getFeedForwardFromList}
    />
  )
}

const ExercisePage = ({ location }) => {
  const isInBrowser = typeof window !== "undefined"

  const { height } = useWindowDimensions()
  const [queryWord, setQueryWord] = useState()
  const [inputSessionId, setInputSessionId] = useState()
  const [wordBuffer, setWordBuffer] = useState([])
  const wordBufferIndex = useRef(0)
  const staticWords = useRef([])
  const staticFeedforwards = useRef({})
  const usedIndices = useRef([])
  const student = useStudentStore((store) => store.student)

  const getNextWordFromList = useCallback(() => {
    let randomIndex
    do {
      randomIndex = Math.floor(Math.random() * staticWords.current.length)
    } while (usedIndices.current.includes(randomIndex))

    usedIndices.current.push(randomIndex)
    if (usedIndices.current.length === 10) {
      usedIndices.current.shift()
    }

    return staticWords.current[randomIndex]
  }, [])

  const getFeedForwardFromList = useCallback(
    (attempt, isCorrect, letterCount, isAbove50) => {
      if (!isCorrect && attempt === 1) {
        return letterCount !== 0
          ? isAbove50
            ? String.fromCodePoint(UNICODE_HEX_VALUES.THINK_FACE)
            : String.fromCodePoint(UNICODE_HEX_VALUES.MONOCLE_FACE)
          : String.fromCodePoint(UNICODE_HEX_VALUES.SLIGHTLY_FROWNING_FACE)
      }

      const feedfwdCategory = isCorrect
        ? "right"
        : letterCount !== 0
        ? attempt !== 2
          ? `partlyRight${attempt.toString()}`
          : isAbove50
          ? `above50_${attempt.toString()}`
          : `beneath50_${attempt.toString()}`
        : `wrong${attempt.toString()}`

      let randomIndex = Math.floor(
        Math.random() * staticFeedforwards.current[feedfwdCategory].length
      )

      const newFeedforward = staticFeedforwards.current[feedfwdCategory][
        randomIndex
      ]
        .split("XY")
        .join(letterCount.toString())

      console.log("load feedforward:", newFeedforward)

      return newFeedforward
    },
    []
  )

  const handleRequestWordError = useCallback(
    async (error) => {
      console.error("something went wrong while requesting next word:", error)
      try {
        await graphqlClient.request(DELETE_INPUT_SESSION_BY_ID_MUTATION, {
          id: inputSessionId,
        })

        console.log("Input Session " + inputSessionId + " deleted.")
      } catch (e) {
        console.error("deleting input session failed:", e)
      } finally {
        navigate("/student/profile")
      }
    },
    [inputSessionId]
  )

  const loadWordsAndFeedForwards = useCallback(async () => {
    if (student) {
      try {
        await Promise.all(
          PKW_LVLS_BY_SKILL_LVL[student.estimatedSkill].map(
            async (pkwLevel) => {
              const wordsQueryResponse = await graphqlClient.request(
                WORDS_BY_PKW_QUERY,
                { pkwLevel }
              )
              console.log(
                `words by pkw level ${pkwLevel}: `,
                wordsQueryResponse
              )

              staticWords.current = staticWords.current.concat(
                wordsQueryResponse.allAustWords.nodes
              )
            }
          )
        )

        staticFeedforwards.current = await fetchFeedforwardsFromCSV()

        const newSession = await graphqlClient.request(
          CREATE_INPUT_SESSION_MUTATION,
          {
            attempt: 0,
            input: "",
            correct: false,
            student: student.id,
          }
        )
        const newSessionId = newSession.createInputSession.inputSession.id
        // const mlPayload = {
        //   attempt: 0,
        //   word: "",
        //   current: "",
        //   isCorrect: true,
        //   uid: student.id,
        //   sid: newSessionId,
        // }

        console.log("created input session:", newSessionId)
        setInputSessionId(newSessionId)

        console.log("word list:", staticWords.current)
        console.log("choose random word from static list")
        setQueryWord(getNextWordFromList())

        // if (!!student.beginnerLevel) {
        //   console.log("choose random word from static list")
        //   setQueryWord(getNextWordFromList())
        // } else {
        //   console.log("ML API payload:", mlPayload)
        //   const mlResponse = await api.pushResult(mlPayload)

        //   console.log("ML Response:", mlResponse)
        //   setWordBuffer(mlResponse.next_words)
        //   setQueryWord(mlResponse.next_words[0])
        //   wordBufferIndex.current = 0
        // }
      } catch (error) {
        handleRequestWordError(error)
      }
    }
  }, [student])

  const checkStudentData = async () => {
    try {
      const updateSuccessResult = await updateCurrentStudentData()
      console.log("update success:", updateSuccessResult)

      if (!updateSuccessResult.success && isInBrowser) {
        window.localStorage.removeItem("jwt_student")
        navigate("/student", { replace: true })
      }
    } catch (error) {
      console.error(error)

      if (isInBrowser) {
        window.localStorage.removeItem("jwt_student")
        navigate("/student", { replace: true })
      }
    }
  }

  useEffect(() => {
    checkStudentData()
    toast(`This browser has ${isMobile ? "" : "no "}virtual keyboard`, {
      type: "info",
    })
  }, [])

  useEffect(() => {
    loadWordsAndFeedForwards()
  }, [])

  useEffect(() => {
    console.log("next word:", queryWord)
  }, [queryWord])

  return (
    <>
      {!!height && (
        <Layout layoutHeight={height}>
          <Seo title="Exercise" />
          <QuizLayout>
            <ToastContainer autoClose={1000} theme="colored" />
            {queryWord && student ? (
              <ExerciseDisplay
                queryWord={queryWord}
                setWord={setQueryWord}
                inputSessionId={inputSessionId}
                setInputSessionId={setInputSessionId}
                wordBuffer={wordBuffer}
                setWordBuffer={setWordBuffer}
                wordBufferIndex={wordBufferIndex}
                getNextWordFromList={getNextWordFromList}
                getFeedForwardFromList={getFeedForwardFromList}
              />
            ) : (
              <Loader className="loader" />
            )}
          </QuizLayout>
        </Layout>
      )}
    </>
  )
}
export default ExercisePage
