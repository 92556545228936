import React, { useContext } from "react"
import { isMobile } from "react-device-detect"

import { KeyboardContext } from "../Keyboard/KeyboardProvider"

import StyledAnswerInput, { ClearInputButton } from "./AnswerInputStyles"
import CloseIcon from "../../lib/icons/Close"

const AnswerInputDisplay = ({
  handleSubmit,
  currentAttempt,
  setInput,
  ...props
}) => {
  const { keyboardState, clearInput, setKeyboardState } =
    useContext(KeyboardContext)
  const { input, currentMode } = keyboardState

  return (
    <StyledAnswerInput>
      <input
        autoFocus
        value={input}
        disabled={isMobile}
        onKeyDown={(e) => {
          const { key } = e
          if (key === "Enter") handleSubmit()
        }}
        onChange={(e) => {
          setInput(e)
        }}
        onContextMenu={(e) => {
          e.preventDefault()
        }}
        onPaste={(e) => {
          e.preventDefault()
        }}
      />
      <ClearInputButton
        disabled={input.length === 0}
        onClick={() => {
          clearInput()
          if (currentMode === "exhausted") {
            setKeyboardState((prev) => ({
              ...prev,
              currentMode: currentAttempt === 4 ? "assist" : "default",
            }))
          }
        }}
      >
        <CloseIcon />
      </ClearInputButton>
    </StyledAnswerInput>
  )
}

export default AnswerInputDisplay
