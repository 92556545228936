import * as React from "react"

function SvgComponent({ size = 58, ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 58 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M56.626 20.733L38.294 18.07 30.099 1.459a1.3 1.3 0 00-2.239 0l-8.199 16.607-18.336 2.667a1.249 1.249 0 00-.694 2.129l13.268 12.931-3.132 18.258a1.248 1.248 0 001.81 1.316l16.398-8.62 16.398 8.616a1.249 1.249 0 001.81-1.316l-3.132-18.254L57.32 22.862a1.249 1.249 0 00-.693-2.129h-.002z"
        fill="url(#prefix__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={20.79}
          y1={21.999}
          x2={33.79}
          y2={37.999}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F59F26" />
          <stop offset={1} stopColor="#E25B15" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgComponent
