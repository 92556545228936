import styled from "styled-components"
import { fluidRange } from "polished"

export const StyledAttemptsList = styled.ol`
  list-style: none;
  margin: 0;
  padding-left: 0;
  align-self: center;
  ${fluidRange({
    prop: "font-size",
    fromSize: "16px",
    toSize: "20px",
  })}
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding: 0 5vw;
  }

  .attempt {
    display: flex;
    align-items: center;
    line-height: 1;
    color: ${({ theme }) => theme.colors.gray400};
    border: 2px solid;
    border-radius: 10em;
    padding-right: 0.25em;
    width: min-content;

    .attempt-count {
      text-align: center;
      width: 2em;
      padding: 0.5em;
      border-radius: 10em;
      font-weight: bold;
      margin-right: 0.5em;
    }

    &[data-variant="failed"] {
      color: ${({ theme }) => theme.colors.feedback.bad.main};

      .attempt-count {
        background-color: ${({ theme }) => theme.colors.feedback.bad.main};
        color: white;
      }
    }

    &[data-variant="success"] {
      color: ${({ theme }) => theme.colors.feedback.good.main};

      .attempt-count {
        background-color: ${({ theme }) => theme.colors.feedback.good.main};
        color: white;
      }
    }

    &[data-variant="current"] {
      color: ${({ theme }) => theme.colors.accent.main};
    }

    .input {
      font-family: "Grundschrift Bold";
      @media (orientation: portrait) {
        font-size: calc(100vw / (64 * 0.64) + 8px);
      }

      @media (orientation: landscape) {
        font-size: calc(100vw / 64 + 8px);
      }
    }
  }
`
