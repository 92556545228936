import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"

import useKeyboardConfig from "./useKeyboardConfig"

export const KeyboardContext = createContext()

const KeyboardProvider = ({ children, modes, maxLength }) => {
  // check if executing instance is the browser
  const isBrowser = typeof window !== "undefined"

  // states
  const [isActive, setIsActive] = useState(true)
  const [keyboardState, setKeyboardState] = useState({
    activeKeys: [],
    input: "",
    rows: [],
    areas: [],
    keysHaveRows: false,
    currentMode: "default",
    lastMode: "default",
    cursorPosition: 0,
  })

  // from hooks
  const [keys, keysDisabled] = useKeyboardConfig(
    modes,
    keyboardState.currentMode
  )

  // refs
  const handlePressEnter = useRef()

  // callbacks
  const registerCallback = useCallback((callback) => {
    if (handlePressEnter.current === callback) return
    handlePressEnter.current = callback
  }, [])

  const unregisterCallback = useCallback((callback) => {
    if (!handlePressEnter.current !== callback) return
    handlePressEnter.current = null
  }, [])

  const activateKey = useCallback(
    (key) => {
      setKeyboardState((prev) => {
        if (prev.activeKeys.includes(key)) return prev
        else
          return {
            ...prev,
            activeKeys: [...prev.activeKeys, key],
          }
      })
    },
    [setKeyboardState]
  )

  const deactivateKey = useCallback(
    (key) => {
      setKeyboardState((prev) => ({
        ...prev,
        activeKeys: prev.activeKeys.filter((k) => k !== key),
      }))
    },
    [setKeyboardState]
  )

  const checkIsKeyActive = useCallback(
    (id) => keyboardState.activeKeys.includes(id),
    [keyboardState.activeKeys]
  )

  const isKeyDisabled = useCallback(
    (keyValue) => !!keysDisabled.find((key) => key.value === keyValue),
    [keysDisabled]
  )

  const handleKeyPress = useCallback(
    (e) => {
      const { code, key, metaKey, shiftKey, type } = e
      console.log("key:", key)

      if (
        key === "Shift" &&
        keyboardState.currentMode !== "exhausted" &&
        keyboardState.currentMode !== "assist"
      ) {
        setKeyboardState((prev) => ({
          ...prev,
          currentMode:
            type === "keydown"
              ? "shift"
              : type === "keyup"
              ? "default"
              : prev.currentMode,
        }))
      } else if (key === "Backspace") {
        if (keyboardState.currentMode === "exhausted") {
          setKeyboardState((prev) => ({ ...prev, currentMode: prev.lastMode }))
        }
      } else if (/^[A-Za-z-]$/.test(key)) {
        if (keyboardState.currentMode === "exhausted" || isKeyDisabled(key)) {
          e.preventDefault()
        } else if (keyboardState.input.length + 1 === maxLength) {
          setKeyboardState((prev) => ({
            ...prev,
            currentMode: "exhausted",
            lastMode: prev.currentMode,
          }))
        }
      } else if (key === "Enter") {
        handlePressEnter.current(key, type)
      } else if (key !== "ArrowLeft" && key !== "ArrowRight") {
        e.preventDefault()
      }
    },
    [keyboardState, maxLength, setKeyboardState, isActive]
  )

  const clearInput = useCallback(() => {
    setKeyboardState((prev) => ({
      ...prev,
      input: "",
      currentMode:
        prev.currentMode === "exhausted" ? prev.lastMode : prev.currentMode,
    }))
  }, [keyboardState.input, setKeyboardState])

  // effects
  useEffect(() => {
    console.log("active keys:", keyboardState.activeKeys)
  }, [keyboardState])

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("keydown", handleKeyPress)
      window.addEventListener("keyup", handleKeyPress)
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress)
      window.removeEventListener("keyup", handleKeyPress)
    }
  }, [handleKeyPress])

  useEffect(() => {
    if (!keys) return

    const keysHaveRows = keys.some((key) => !!key.row)
    const zones = keys.reduce(
      (prev, { row, area }) => Math.max(prev, (keysHaveRows ? row : area) + 1),
      1
    )
    const keysPerZone = new Array(zones)
      .fill(0)
      .map((_, i) =>
        keys
          ? keys.filter(({ row, area }) => (keysHaveRows ? row : area) === i)
          : []
      )

    setKeyboardState((prev) => ({
      ...prev,
      rows: keysHaveRows ? keysPerZone : [],
      areas: !keysHaveRows ? keysPerZone : [],
      keysHaveRows,
    }))
  }, [keys])

  // keyboard context
  const keyboardContext = useMemo(
    () => ({
      keyboardState,
      keys: keys,
      maxLength,
      setKeyboardState,
      activateKey,
      deactivateKey,
      checkIsKeyActive,
      isKeyDisabled,
      registerCallback,
      unregisterCallback,
      clearInput,
      setIsActive,
    }),
    [
      keyboardState,
      keys,
      maxLength,
      setKeyboardState,
      activateKey,
      deactivateKey,
      checkIsKeyActive,
      registerCallback,
      unregisterCallback,
      isKeyDisabled,
      clearInput,
      setIsActive,
    ]
  )

  // component
  return (
    <KeyboardContext.Provider value={keyboardContext}>
      {children}
    </KeyboardContext.Provider>
  )
}

KeyboardProvider.propTypes = {
  children: PropTypes.node,
  maxLength: PropTypes.number,
  modes: PropTypes.object,
}

export default KeyboardProvider
