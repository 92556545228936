import React from "react"
import PropTypes from "prop-types"

import { StyledEmoji } from "./EmojiStyles"

// wrapper to easily add accessible emojis
const Emoji = ({ children, label, size = "", className = "" }) => (
  <StyledEmoji
    className={[className, size].join(" ")}
    role="img"
    aria-label={label}
    aria-hidden={label ? undefined : "true"}
  >
    {children}
  </StyledEmoji>
)

Emoji.propTypes = {
  children: PropTypes.string,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["", "s", "xs", "xxs", "l", "xl", "xxl"]),
  className: PropTypes.string,
}

export default Emoji
