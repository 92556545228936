// basic stuff
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

// components
import TaskListingDisplay from "./TaskListingDisplay"
import ConfirmationAlert from "../ConfirmationAlert"

// hooks
import { usePoints } from "../../hooks"

// context variables
import { KeyboardContext } from "../Keyboard/KeyboardProvider"

// exercise validation
import { exerciseService } from "../../services/validation/exercise"

// graphQL stuff
import graphqlClient from "../../services/graphql-client.js"
import {
  CREATE_INPUT_SESSION_MUTATION,
  UPDATE_INPUT_SESSION_BY_ID_MUTATION,
  DELETE_INPUT_SESSION_BY_ID_MUTATION,
} from "../../graphql_requests"

// constants
import {
  DEFAULT_MESSAGES,
  DEFAULT_EXERCISE_STATE,
  EXERCISE_TASKS,
  ACTIVITY_TIMEOUT_MS,
} from "../../const_values"

// current user
import { useStudentStore } from "../../../store"

// time logger
import { calculateStarsFromAttempt, TimeLogger } from "../../services/helpers"
import { toast } from "react-toastify"

// main component
const TaskListing = ({
  task,
  setWord,
  inputSessionId,
  setInputSessionId,
  wordBuffer,
  setWordBuffer,
  wordBufferIndex,
  getNextWordFromList,
  getFeedForwardFromList,
}) => {
  // states
  const [warning, setWarning] = useState(null)
  const [hasWarning, setHasWarning] = useState(false)
  const [isSolvingNoWarning, setIsSolvingNoWarning] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [isFailure, setIsFailure] = useState(false)
  const [isOver, setIsOver] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLastAttempt, setIsLastAttempt] = useState(false)
  const [showElaborateFeedback, setShowElaborateFeedback] = useState(false)
  const [exerciseState, setExerciseState] = useState(DEFAULT_EXERCISE_STATE)
  const [currentTask, setCurrentTask] = useState(EXERCISE_TASKS.solving)
  const [loadingTask, setLoadingTask] = useState(false)
  const [isContinueConfirmationOpen, setIsContinueConfirmationOpen] =
    useState(false)

  const student = useStudentStore((store) => store.student)
  const appendExerciseToStore = useStudentStore(
    (store) => store.appendCoveredExercise
  )
  const sessionTimer = useRef(new TimeLogger())
  const feedbackTimer = useRef(new TimeLogger())
  const activityTimeout = useRef()

  const keyboardContext = useContext(KeyboardContext)

  const { updateStars } = usePoints()

  const requestedSequence = useMemo(
    () =>
      task.request.map((sequence, index) => ({
        index,
        sequence,
        variant: "correct",
      })),
    [task.request]
  )
  const attempts = useMemo(
    () =>
      !!exerciseState.inputs.length
        ? exerciseState.inputs.map((input) => exerciseState.results[input])
        : [],
    [exerciseState.inputs, exerciseState.results]
  )

  const handleActivityTimeout = useCallback(() => {
    switch (currentTask) {
      case EXERCISE_TASKS.solving:
        sessionTimer.current.end()
        break
      case EXERCISE_TASKS.failure:
      case EXERCISE_TASKS.over:
      case EXERCISE_TASKS.success:
        feedbackTimer.current.end()
    }

    setIsContinueConfirmationOpen(true)
  }, [currentTask])

  const handleContiuneConfirm = useCallback(() => {
    switch (currentTask) {
      case EXERCISE_TASKS.solving:
        sessionTimer.current.reset()
        sessionTimer.current.start()
        break
      case EXERCISE_TASKS.failure:
      case EXERCISE_TASKS.over:
      case EXERCISE_TASKS.success:
        feedbackTimer.current.reset()
        feedbackTimer.current.start()
    }

    clearTimeout(activityTimeout.current)
    activityTimeout.current = setTimeout(
      handleActivityTimeout,
      ACTIVITY_TIMEOUT_MS
    )

    setIsContinueConfirmationOpen(false)
  }, [currentTask])

  const handleContiuneAbort = useCallback(async function () {
    try {
      await graphqlClient.request(DELETE_INPUT_SESSION_BY_ID_MUTATION, {
        id: inputSessionId,
      })
    } catch (error) {
      toast.error(`failed to delete input session: ${error.message}`)
      console.error(error)
    }
    navigate("/student/profile", { replace: true })
  }, [])

  const handleClearWarning = () => setWarning(null)
  const handleAbort = useCallback(async () => {
    sessionTimer.current.reset()
    feedbackTimer.current.reset()
    try {
      await graphqlClient.request(DELETE_INPUT_SESSION_BY_ID_MUTATION, {
        id: inputSessionId,
      })
      console.log("input session ", inputSessionId, " deleted.")
      setInputSessionId(null)
    } catch (error) {
      console.error("Input Session Delete Error:", error)
    }
    navigate("/student/profile", { replace: true })
  }, [inputSessionId, exerciseState])

  const handleRetry = useCallback(async () => {
    feedbackTimer.current.end()

    console.log("exercise state on retry:", exerciseState)

    try {
      await switchInputSession(
        {
          id: inputSessionId,
          attempt: exerciseState.currentAttempt - 1,
          correct: exerciseState.isSolved,
          input: keyboardContext.keyboardState.input,
          word: task.word,
          durationMs: sessionTimer.current.calculateDuration(),
          durationMsFb: feedbackTimer.current.calculateDuration(),
          hitsCount: exerciseState.results.graphemeHits.count,
          startTime: sessionTimer.current.timeStart,
          skillLevel: student.estimatedSkill,
        },
        {
          attempt: 0,
          input: "",
          correct: false,
          word: task.word,
          student: student.id,
        }
      )

      setCurrentTask(EXERCISE_TASKS.solving)
    } catch (error) {
      console.error("failed to append exercise:", error)
    }
    keyboardContext.clearInput()
  }, [exerciseState, keyboardContext.keyboardState.input, student, task])

  const handleSubmit = useCallback(async () => {
    if (!keyboardContext.keyboardState.input) {
      setWarning(`You need to enter some letters first! Try again!`)
    } else if (
      exerciseState.inputs.includes(keyboardContext.keyboardState.input)
    ) {
      setWarning(`You tried this before! Try it differently!`)
      keyboardContext.clearInput()
    } else {
      setExerciseState((prevState) => ({
        ...prevState,
        inputs: [...prevState.inputs, keyboardContext.keyboardState.input],
        attempts: prevState.attempts + 1,
        feedback: DEFAULT_MESSAGES.feedback,
        feedforward: DEFAULT_MESSAGES.feedforward,
      }))

      try {
        console.log("exercise state:", exerciseState)

        setCurrentTask(EXERCISE_TASKS.validating)

        const serviceResponse = await exerciseService(
          {
            request: task.request,
            sid: inputSessionId,
            wordBuffer,
            setWordBuffer,
            wordBufferIndex,
            getNextWordFromList,
            ...exerciseState,
          },
          {
            value: keyboardContext.keyboardState.input,
            word: task.word,
          }
        )

        console.log("service response:", serviceResponse)

        const newCorrect = serviceResponse.matched.map(
          (e, i) => e || exerciseState.correct[i]
        )

        console.log("set feedforward")

        setExerciseState((prevState) => ({
          ...prevState,
          isSolved: true,
          correct: newCorrect,
          feedforward: getFeedForwardFromList(
            exerciseState.currentAttempt,
            true,
            serviceResponse.graphemeHits.letterCount,
            serviceResponse.graphemeHits.letterCount / task.word.length >= 0.5
          ),
          results: { ...prevState.results, ...serviceResponse },
        }))

        wordBufferIndex.current = 0

        setCurrentTask(EXERCISE_TASKS.success)
      } catch (error) {
        console.error("service error:", error)

        // if (
        //   !!!student.beginnerLevel &&
        //   (exerciseState.currentAttempt === 2 || exerciseState.currentAttempt === task.maxAttempts)
        // ) {
        //   try {
        //     let fdFwd = ""

        //     if (error.current_error instanceof Array) {
        //       fdFwd = (
        //         await Promise.all(
        //           error.current_error.map(async (error) => {
        //             const response = await graphqlClient.request(
        //               FEEDBACK_QUERY,
        //               {
        //                 regel: error,
        //               }
        //             )
        //             console.log("response multi:", response)
        //             return response.feedbackByRegel.feedForward
        //           })
        //         )
        //       ).join("\n")
        //     } else {
        //       const response = await graphqlClient.request(FEEDBACK_QUERY, {
        //         regel: error.current_error,
        //       })
        //       console.log("response single:", response)
        //       fdFwd = response.feedbackByRegel.feedForward
        //     }

        //     console.log("feedforward:", fdFwd)
        //     setExerciseState((prevState) => ({
        //       ...prevState,
        //       feedforward: fdFwd,
        //     }))
        //   } catch (error) {
        //     console.error("failed at processing mistake:", error)
        //   }
        // } else if (!!student.beginnerLevel) {
        console.log("set feedforward")

        setExerciseState((prevState) => ({
          ...prevState,
          feedforward: getFeedForwardFromList(
            exerciseState.currentAttempt,
            false,
            error.graphemeHits.letterCount,
            error.graphemeHits.letterCount / task.word.length >= 0.5
          ),
        }))
        // }

        const newCorrect = error.matched.map(
          (e, i) => e || exerciseState.correct[i]
        )

        setCurrentTask(
          exerciseState.currentAttempt === task.maxAttempts
            ? EXERCISE_TASKS.over
            : EXERCISE_TASKS.failure
        )

        setExerciseState((prevState) => ({
          ...prevState,
          currentAttempt:
            prevState.currentAttempt < 4
              ? prevState.currentAttempt + 1
              : prevState.currentAttempt,
          isSolved: false,
          correct: newCorrect,
          results: { ...prevState.results, ...error },
        }))
      }
    }
  }, [
    keyboardContext.keyboardState.input,
    exerciseState.inputs,
    task,
    exerciseState,
  ])

  const handleFinish = () => navigate("/student/profile", { replace: true })
  const handleNext = useCallback(async () => {
    feedbackTimer.current.end()
    keyboardContext.clearInput()

    try {
      await appendExercise({
        request: task.request,
        input: keyboardContext.keyboardState.input,
        attempt: exerciseState.currentAttempt,
        isSolved: exerciseState.isSolved,
        word: task.word,
        uid: task.uid,
        correct: exerciseState.correct,
        hitsCount: exerciseState.results.graphemeHits.count,
        startTime: sessionTimer.current.timeStart,
      })
      await updateStars(
        calculateStarsFromAttempt({
          attempt: exerciseState.currentAttempt,
          hitsCount: exerciseState.results.graphemeHits.count,
        })
      )
      setWord(exerciseState.results.next)
      setCurrentTask(EXERCISE_TASKS.solving)
      setExerciseState((prevState) => ({
        ...prevState,
        inputs: [],
      }))
    } catch (error) {
      console.error("failed to append exercise:", error)
    }
  }, [exerciseState, task])

  const appendExercise = useCallback(
    async (exercise) => {
      await switchInputSession(
        {
          id: inputSessionId,
          attempt: exercise.attempt,
          correct: exercise.isSolved,
          input: exercise.input,
          word: exercise.word,
          durationMs: sessionTimer.current.calculateDuration(),
          durationMsFb: feedbackTimer.current.calculateDuration(),
          startTime: sessionTimer.current.timeStart,
          hitsCount: exercise.hitsCount,
          skillLevel: student.estimatedSkill,
        },
        {
          attempt: 0,
          input: "",
          correct: false,
          word: exerciseState.results.next.word,
          student: student.id,
        }
      )

      appendExerciseToStore(exercise)
    },
    [exerciseState.results, student]
  )

  const switchInputSession = useCallback(
    async (updatePayload, createPayload) => {
      console.log("input session id:", inputSessionId)
      const updateResponse = await graphqlClient.request(
        UPDATE_INPUT_SESSION_BY_ID_MUTATION,
        { ...updatePayload }
      )
      console.log("input session updated:", updateResponse)

      if (exerciseState.results.continue) {
        const createResponse = await graphqlClient.request(
          CREATE_INPUT_SESSION_MUTATION,
          { ...createPayload }
        )
        console.log("input session created:", createResponse)

        setInputSessionId(createResponse.createInputSession.inputSession.id)
        console.log(
          "set new session id:",
          createResponse.createInputSession.inputSession.id
        )
      } else {
        console.log(
          "cannot load next exercise for some reason, return to profile"
        )
        navigate("/student/profile", { replace: true })
      }
    },
    [exerciseState.results]
  )

  useEffect(() => {
    window.onpopstate = () => {
      sessionTimer.current.reset()
      feedbackTimer.current.reset()
      graphqlClient
        .request(DELETE_INPUT_SESSION_BY_ID_MUTATION, {
          id: inputSessionId,
        })
        .then(
          (response) => {
            console.log("input session ", inputSessionId, " deleted.")
            setInputSessionId(null)
          },
          (reject) => {
            console.error("Input Session Delete Error:", reject)
          }
        )
    }

    activityTimeout.current = setTimeout(
      handleActivityTimeout,
      ACTIVITY_TIMEOUT_MS
    )

    return () => {
      window.onpopstate = null
    }
  }, [])

  useEffect(() => {
    if (activityTimeout.current) {
      clearTimeout(activityTimeout.current)
      activityTimeout.current = setTimeout(
        handleActivityTimeout,
        ACTIVITY_TIMEOUT_MS
      )
    }
  }, [keyboardContext.keyboardState.input])

  useEffect(() => {
    console.log("task:", task)
    console.log("exercise state:", exerciseState)
  }, [task, exerciseState])

  useEffect(() => {
    setLoadingTask(true)
    setExerciseState((prevState) => ({
      ...prevState,
      results: {},
      currentAttempt: 1,
    }))
    setLoadingTask(false)
  }, [task])

  useEffect(() => {
    setIsLastAttempt(exerciseState.currentAttempt === task.maxAttempts)
  }, [exerciseState.currentAttempt, task.maxAttempts])

  useEffect(() => {
    setShowElaborateFeedback(true)
  }, [exerciseState.currentAttempt, isLastAttempt, student])

  useEffect(() => {
    if (isLastAttempt)
      keyboardContext.setKeyboardState((prev) => ({
        ...prev,
        currentMode: "assist",
      }))
    else
      keyboardContext.setKeyboardState((prev) => ({
        ...prev,
        currentMode: "default",
      }))
  }, [isLastAttempt, keyboardContext.setKeyboardState])

  useEffect(() => {
    const callback = (value, direction) => {
      if (value === "Enter" && direction === "up") handleSubmit()
    }
    keyboardContext.registerCallback(callback)
    return () => keyboardContext.unregisterCallback(callback)
  }, [
    keyboardContext.registerCallback,
    keyboardContext.unregisterCallback,
    handleSubmit,
  ])

  useEffect(() => {
    keyboardContext.setIsActive(
      !warning && currentTask === EXERCISE_TASKS.solving
    )
    setHasWarning(!!warning)
    setIsSolvingNoWarning(currentTask === EXERCISE_TASKS.solving && !!!warning)
    setIsValidating(currentTask === EXERCISE_TASKS.validating)
    setIsFailure(currentTask === EXERCISE_TASKS.failure)
    setIsOver(currentTask === EXERCISE_TASKS.over)
    setIsSuccess(currentTask === EXERCISE_TASKS.success)

    if (currentTask === EXERCISE_TASKS.solving && task) {
      sessionTimer.current.start()
    } else if (currentTask === EXERCISE_TASKS.validating) {
      sessionTimer.current.end()
    } else if (
      currentTask === EXERCISE_TASKS.failure ||
      currentTask === EXERCISE_TASKS.over ||
      currentTask === EXERCISE_TASKS.success
    ) {
      feedbackTimer.current.start()
    }
  }, [currentTask, warning, keyboardContext.setIsActive, task])

  useEffect(() => {
    console.log("current feedforward:", exerciseState.feedforward)
  }, [exerciseState.feedforward])

  return (
    <>
      <ConfirmationAlert
        isOpen={isContinueConfirmationOpen}
        toggleOpen={() => setIsContinueConfirmationOpen((prev) => !prev)}
        headerText="Hello! Are you there??"
        bodyText="Do you want to continue?"
        handleConfirm={handleContiuneConfirm}
        handleAbort={handleContiuneAbort}
      />
      <TaskListingDisplay
        task={task}
        exerciseState={exerciseState}
        requestedSequence={requestedSequence}
        attempts={attempts}
        handleAbort={handleAbort}
        handleClearWarning={handleClearWarning}
        handleRetry={handleRetry}
        handleFinish={handleFinish}
        handleNext={handleNext}
        isLastAttempt={isLastAttempt}
        isSolvingNoWarning={isSolvingNoWarning}
        isValidating={isValidating}
        isOver={isOver}
        isFailure={isFailure}
        isSuccess={isSuccess}
        hasWarning={hasWarning}
        showElaborateFeedback={showElaborateFeedback}
        warning={warning}
        handleSubmit={handleSubmit}
        loadingTask={loadingTask}
      />
    </>
  )
}

TaskListing.propTypes = {
  task: PropTypes.shape({
    maxAttempts: PropTypes.number.isRequired,
    request: PropTypes.arrayOf(PropTypes.string).isRequired,
    word: PropTypes.string.isRequired,
    sentence: PropTypes.string.isRequired,
    pkwLevel: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    audioWord: PropTypes.string.isRequired,
    audioSentence: PropTypes.string.isRequired,
    playbackRate: PropTypes.number,
    maxInputLength: PropTypes.number.isRequired,
  }).isRequired,
  setWord: PropTypes.func.isRequired,
  inputSessionId: PropTypes.string.isRequired,
  setInputSessionId: PropTypes.func.isRequired,
  wordBuffer: PropTypes.array,
  setWordBuffer: PropTypes.func,
  wordBufferIndex: PropTypes.shape({
    current: PropTypes.number.isRequired,
  }),
  getNextWordFromList: PropTypes.func,
  getFeedForwardFromList: PropTypes.func,
}

export default TaskListing
