import React, { useState, useEffect, useCallback, useRef } from "react"
import { toast } from "react-toastify"

const useAudio = (url, autoPlay) => {
  // const [audio, setAudio] = useState()
  const audioElement = useRef(new Audio())
  // const [isPlayable, setIsPlayable] = useState(false)

  const playOnLoad = useCallback(() => {
    console.log("audio is playable")
    // setIsPlayable(true)
    if (autoPlay) audioElement.current.play()
  }, [autoPlay, url])

  const handleSourceError = useCallback(() => {
    toast(`Audio from ${audioElement.current.src} could not be loaded`, {
      type: "error",
      autoClose: 1500,
    })
  }, [url])

  const play = useCallback(() => {
    if (audioElement.current.networkState === 1) audioElement.current.play()
    // if (isPlayable) audio.play()
    else
      toast(`Audio from ${url} could not be loaded`, {
        type: "error",
        autoClose: 1500,
      })
  }, [])

  // useEffect(() => {
  //   if (!!audio) {
  //     audio.addEventListener("canplaythrough", playOnLoad)
  //     audio.addEventListener("error", handleSourceError)
  //   }

  //   return () => {
  //     if (!!audio) {
  //       audio.removeEventListener("canplaythrough", playOnLoad)
  //       audio.removeEventListener("error", handleSourceError)
  //     }
  //   }
  // }, [audio])

  useEffect(() => {
    audioElement.current.addEventListener("error", handleSourceError)
    audioElement.current.addEventListener("canplaythrough", playOnLoad)
  }, [])

  useEffect(() => {
    // setIsPlayable(false)
    // setAudio(new Audio(url))

    // if (!!!audioElement.current) {
    //   audioElement.current = new Audio()

    //   audioElement.current.onError = handleSourceError
    //   audioElement.current.onCanPlayThrough = playOnLoad
    // }

    audioElement.current.autoPlay = autoPlay
    audioElement.current.src = url
  }, [autoPlay, url])

  return [play, audioElement]
}

export default useAudio
