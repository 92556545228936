import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { isMobile } from "react-device-detect"

import KeyDisplay from "./KeyDisplay"

const Key = ({
  value,
  icon,
  disabled,
  handleActivate,
  handleDeactivate,
  handleKeyEvent,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false)

  const handleKeyPress = useCallback(
    (e) => {
      console.log("key press event:", e)
      setIsActive(true)
      handleActivate()

      if (e.button === 0 || isMobile) {
        handleKeyEvent()
      }
    },
    [handleActivate]
  )

  const handleKeyRelease = useCallback(
    (e) => {
      console.log("key release event:", e)
      setIsActive(false)
      handleDeactivate()
    },
    [handleDeactivate]
  )

  return (
    <KeyDisplay
      handleKeyPress={handleKeyPress}
      handleKeyRelease={handleKeyRelease}
      isMobile={isMobile}
      disabled={disabled}
      isActive={isActive}
      icon={icon}
      value={value}
      {...props}
    />
  )
}

Key.propTypes = {
  active: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  row: PropTypes.number,
  className: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleActivate: PropTypes.func,
  handleDeactivate: PropTypes.func,
  handleKeyEvent: PropTypes.func,
}

export default Key
