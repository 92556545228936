import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
      <title>{"f-chat"}</title>
      <path d="M28 1H4a4 4 0 00-4 4v16a4 4 0 004 4h6.531L16 31.562 21.469 25H28a4 4 0 004-4V5a4 4 0 00-4-4zM18 17H7v-2h11zm7-6H7V9h18z" />
    </svg>
  )
}

export default SvgComponent
