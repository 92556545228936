import styled from "styled-components"

import { IconButtonBase } from "../../styles/buttons"

const StyledAnswerInput = styled.article`
  align-self: center;
  justify-self: center;

  font-family: "Grundschrift Normal", sans-serif;
  font-style: normal;
  width: 60vw;
  max-width: 800px;
  padding: 0.35em 0.5em 0.35em 1em;
  background-color: white;
  border-radius: 10em;
  box-shadow: inset 0 0 0 3px ${({ theme }) => theme.colors.gray800},
    ${({ theme }) => theme.shadows.small};

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.fluid.small}vw;

  line-height: 1;

  & > input {
    width: 100%;
    border: none;
    border-bottom: 3px solid gray;
    border-radius: 5px;
    caret-color: blue;

    &:focus {
      border-bottom: 3px solid black;
      outline: none;
    }

    &:disabled {
      border-bottom-color: black;
      color: black;
      opacity: 1;
      -webkit-text-fill-color: black;
    }
  }
`

export const ClearInputButton = styled(IconButtonBase)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary.shade};
  border-radius: 10em;
  box-shadow: none;
  border: 3px solid;
  padding: 0.15em;

  svg {
    width: 0.5em;
    height: 0.5em;
  }
`

export default StyledAnswerInput
