import { analyseInput } from "../../utils"

// import { buildPayload } from "../../utils/serializers/mlpayload"
// import Api from "../api"

// const api = new Api()

export const exerciseService = async (
  {
    request,
    sid,
    wordBuffer,
    setWordBuffer,
    wordBufferIndex,
    getNextWordFromList,
    ...context
  },
  { value, word }
) => {
  const analysis = analyseInput(request, value)
  const [isCorrect, input, segments, , graphemes, graphemeHits] = analysis
  const matched = graphemes.map(({ variant }) => variant === "correct")

  const results = {
    matched,
    [input]: segments,
    next:
      isCorrect || context.currentAttempt === 4 ? getNextWordFromList() : null,
    isCorrect,
    graphemeHits,
    continue: true,
  }

  return isCorrect ? Promise.resolve(results) : Promise.reject(results)
  // } else {
  //   const payload = buildPayload({ request, ...context }, analysis, word)

  //   console.groupCollapsed("ML API")
  //   console.info("send payload to ml api")
  //   console.log(payload, sid)
  //   console.groupEnd("ML API")

  // try {
  //   const mlResponse = await api.pushResult({ ...payload, sid })
  //   console.log("ML API Response:", mlResponse)
  //   console.log("ML API push result and aquire next words successful")

  //   const results = {
  //     ...mlResponse,
  //     matched,
  //     [input]: segments,
  //     next: mlResponse.next_words[0],
  //     isCorrect,
  //     graphemeHits,
  //     continue: true,
  //   }

  //   if (mlResponse.status === Api.SUCCESS && isCorrect) {
  //     setWordBuffer(mlResponse.next_words)
  //     wordBufferIndex.current = 0

  //     return Promise.resolve(results)
  //   } else {
  //     return Promise.reject({ ...results, message: "input not correct" })
  //   }
  // } catch (error) {
  //   console.error("ML API failed to aquire next words:", error)

  //   if (wordBufferIndex.current < wordBuffer.length - 1) {
  //     console.log("ML API continue with current word selection")

  //     const results = {
  //       matched,
  //       [input]: segments,
  //       next: wordBuffer[wordBufferIndex.current + 1],
  //       isCorrect,
  //       graphemeHits,
  //       continue: true,
  //     }

  //     if (isCorrect) {
  //       wordBufferIndex.current = wordBufferIndex.current++

  //       return Promise.resolve(results)
  //     } else {
  //       if (context.currentAttempt === context.maxAttempts) {
  //         wordBufferIndex.current = wordBufferIndex.current++
  //       }

  //       return Promise.reject({
  //         ...results,
  //         message: "input not correct, connection to ML API failed",
  //       })
  //     }
  //   } else {
  //     return Promise.reject({
  //       message:
  //         "ML API no next words left. Navigate back to student profile",
  //       continue: false,
  //     })
  //   }
  // }
}
