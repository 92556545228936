import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Confetti from "react-confetti"

import Tali from "../Tali"
import AttemptsList from "../AttemptsList"
import Emoji from "../Emoji"
import Word from "../Word"
import ControlsWrapper from "../QuizControlsWrapper"
import ImageQuestion from "../ImageQuestion"
import KeyboardContainer from "../Keyboard"
import AnswerInput from "../AnswerInput"
import CurrentUser from "../CurrentUser"
import StarsCount from "../StarsCount"
import StarsFeedback from "../StarsFeedback"

import { Loader } from "../../styles/common"
import { MainButton, BackButton } from "../../styles/buttons"

import { theme } from "../../styles/theme"
import ArrowIcon from "../../lib/icons/Arrow"

import useWindowDimensions from "../../hooks/useWindowDimensions"

const AttemptsDisplay = ({ attempts }) => (
  <ul className="attempts">
    {attempts.map((segments, i) => (
      <li key={`attempt-${i}`}>
        <Word segments={segments} displayChars displayFaulty hideMissing />
      </li>
    ))}
  </ul>
)

const TaskListingDisplay = ({
  task,
  exerciseState,
  requestedSequence,
  attempts,
  handleAbort,
  handleClearWarning,
  handleRetry,
  handleNext,
  handleSubmit,
  isLastAttempt,
  isSolvingNoWarning,
  isValidating,
  isFailure,
  isOver,
  isSuccess,
  hasWarning,
  showElaborateFeedback,
  warning,
  loadingTask,
}) => {
  const { width, height } = useWindowDimensions()

  useEffect(() => {
    console.log("attempts:", attempts)
  }, [attempts])

  return (
    <>
      <BackButton onClick={handleAbort} alt="Cancel Exercise">
        <ArrowIcon />
      </BackButton>

      <aside className="infos">
        <StarsCount />
        <CurrentUser pos="inline" />
      </aside>

      <p className="welcome-hint">What do you see?</p>

      {!isFailure && !hasWarning && !isSuccess && !isOver && <Tali />}

      {isFailure && (
        <Tali
          title={
            showElaborateFeedback && exerciseState.feedforward
              ? exerciseState.feedforward
              : "😊"
          }
          buttonLabel={isLastAttempt ? "Last Attempt" : "Try Again"}
          buttonAction={handleRetry}
          mood="curious"
          backdrop={true}
        >
          {" "}
          {showElaborateFeedback && exerciseState.feedback && (
            <p>
              <strong>{exerciseState.feedback}</strong>
            </p>
          )}
          {/* {showElaborateFeedback && exerciseState.feedforward && (
             <>
               <p>
                 <strong>{exerciseState.feedforward}</strong>
               </p>
             </>
           )} */}
          {/* {exerciseState.currentAttempt === 4 && (
            <p>Noch nicht! Versuche es nochmal mit diesen Buchstaben!</p>
          )} */}
        </Tali>
      )}

      {hasWarning && (
        <Tali
          title="☝️ Oops!"
          buttonLabel="Try Again"
          buttonAction={handleClearWarning}
          mood="curious"
          backdrop={true}
        >
          <p>{warning}</p>
        </Tali>
      )}

      {isOver && (
        <Tali
          title={
            exerciseState.feedforward
              ? exerciseState.feedforward
              : "😉 You'll get it next time!"
          }
          buttonLabel="Next Task"
          buttonAction={handleNext}
          mood="curious"
          backdrop={true}
        >
          {exerciseState.feedback && (
            <p>
              <strong>{exerciseState.feedback}</strong>
            </p>
          )}
          {/* {exerciseState.feedforward && (
            <p>
              <strong>{exerciseState.feedforward}</strong>
            </p>
          )} */}{" "}
          <Word segments={requestedSequence} />
          <p>
            <em>{task.request.length}</em> difficult spots
          </p>
          <p>{exerciseState.currentAttempt} attempts:</p>
          <AttemptsDisplay attempts={attempts} />
        </Tali>
      )}

      {isSuccess && (
        <>
          <Tali
            title={
              exerciseState.feedforward
                ? exerciseState.feedforward
                : isLastAttempt
                ? "😄 You did it!"
                : "🎉 Great!"
            }
            mood="happy"
            backdrop={true}
            buttonAction={handleNext}
            buttonLabel="Next Task"
            // secondaryButton={
            //   !isLastAttempt && (
            //     <SmallIconButton onClick={handleFinish}>
            //       <DetailsIcon />
            //     </SmallIconButton>
            //   )
            // }
            background={
              <Confetti
                width={width}
                height={height}
                confettiSource={{
                  x: width / 3 - width / 8,
                  y: (height / 4) * 2,
                  w: width / 3,
                  h: 0,
                }}
                colors={[
                  theme.colors.accent.main,
                  theme.colors.accent.shade,
                  theme.colors.feedback.good.main,
                  theme.colors.feedback.good.shade,
                ]}
                initialVelocityX={6}
                initialVelocityY={18}
                gravity={0.15}
              />
            }
          >
            {/* {exerciseState.feedforward && (
              <p>
                <h2>{exerciseState.feedforward}</h2>
              </p>
            )} */}

            <Word segments={requestedSequence} />

            <p>
              {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
              <Emoji label="Daumen hoch">👍</Emoji>{" "}
              <em>{task.request.length}</em> difficult spots!
            </p>
            <br />
            <StarsFeedback
              attempt={exerciseState.currentAttempt}
              hitsCount={exerciseState.results.graphemeHits?.count}
            />
            <br />
            <p>your attempts:</p>
            <AttemptsDisplay attempts={attempts} />
          </Tali>
        </>
      )}

      <AttemptsList
        className="attempts"
        current={exerciseState.currentAttempt}
        isSolved={exerciseState.isSolved}
        inputs={attempts}
        unleashTheCarnifex
      />

      <ImageQuestion className="question" task={task} autoPlay={true} />

      <div className="answer">
        <AnswerInput
          handleSubmit={handleSubmit}
          currentAttempt={exerciseState.currentAttempt}
        />
        <MainButton onClick={handleSubmit}>
          <ArrowIcon />
        </MainButton>
      </div>

      <ControlsWrapper>
        {isSolvingNoWarning && (
          <KeyboardContainer currentAttempt={exerciseState.currentAttempt} />
        )}

        {isValidating && <Loader />}
      </ControlsWrapper>
    </>
  )
}

TaskListingDisplay.propTypes = {
  task: PropTypes.shape({
    maxAttempts: PropTypes.number.isRequired,
    request: PropTypes.arrayOf(PropTypes.string).isRequired,
    word: PropTypes.string.isRequired,
    sentence: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    audioWord: PropTypes.string.isRequired,
    audioSentence: PropTypes.string.isRequired,
    playbackRate: PropTypes.number,
    maxInputLength: PropTypes.number.isRequired,
  }).isRequired,
  exerciseState: PropTypes.shape({
    feedback: PropTypes.string,
    feedforward: PropTypes.string,
    inputs: PropTypes.array,
    currentAttempt: PropTypes.number,
    isSolved: PropTypes.bool,
    correct: PropTypes.array,
    results: PropTypes.shape({
      matched: PropTypes.array,
      next: PropTypes.string,
      isCorrect: PropTypes.bool,
      graphemeHits: PropTypes.shape({
        count: PropTypes.number,
        letterCount: PropTypes.number,
      }),
      continue: PropTypes.bool,
      message: PropTypes.string,
    }),
  }).isRequired,
  attempts: PropTypes.any,
  handleAbort: PropTypes.func,
  handleClearWarning: PropTypes.func,
  handleRetry: PropTypes.func,
  handleNext: PropTypes.func,
  isLastAttempt: PropTypes.bool,
  isSolvingNoWarning: PropTypes.bool,
  isValidating: PropTypes.bool,
  isFailure: PropTypes.bool,
  isOver: PropTypes.bool,
  isSuccess: PropTypes.bool,
  hasWarning: PropTypes.bool,
  showElaborateFeedback: PropTypes.bool,
  warning: PropTypes.string,
  handleSubmit: PropTypes.func,
}

export default TaskListingDisplay
