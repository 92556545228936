import React from "react"
import PropTypes from "prop-types"

import { StyledKey } from "./KeyStyles"

const KeyDisplay = ({
  handleKeyPress,
  handleKeyRelease,
  isMobile,
  isActive,
  disabled,
  icon,
  value,
  ...props
}) => {
  return (
    <StyledKey
      onMouseDown={isMobile ? null : handleKeyPress}
      onTouchStart={isMobile ? handleKeyPress : null}
      onMouseUp={isMobile ? null : handleKeyRelease}
      onTouchEnd={isMobile ? handleKeyRelease : null}
      onContextMenu={(e) => e.preventDefault()}
      active={isActive}
      disabled={disabled}
      {...props}
    >
      <span className="char">
        {icon}
        {value}
      </span>
    </StyledKey>
  )
}

KeyDisplay.propTypes = {
  handleKeyPress: PropTypes.func,
  handleKeyRelease: PropTypes.func,
  isMobile: PropTypes.bool,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  value: PropTypes.string,
}

export default KeyDisplay
